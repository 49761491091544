<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('report.group_share/settleadd')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.id" size="small" clearable placeholder="结算单ID">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.status" style="width: 120px" clearable placeholder="结算状态">
					<el-option v-for="(item, index) in statusText" :label="item" :value="index" v-bind:key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.dates" clearable type="daterange" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" start-placeholder="开始时期" end-placeholder="结束时期" size="small">
				</el-date-picker>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" type="expand" align="center" width="40px">
				<template #content="{ row }">
					<div class="expand-wrapper">
						<vxe-grid :columns="[{'field':'income','title':'充电营收'},{'field':'occupy','title':'占位营收'},{'field':'pay','title':'扣款'},{'field':'percent','title':'共建方分成'},{'field':'reset','title':'调整费用'}]" :data="row.childData"></vxe-grid>
					</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="id" title="结算单ID" align="center" />
			<vxe-table-column slot="table-item" field="group_name" title="结算周期" align="center">
				<template v-slot="{row}">
					{{ row.btime.substr(0,10)+"~"+row.etime.substr(0,10) }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="income" title="充电营收" :title-help="{message: '充电营收（包括：自营订单金额、第三方订单金额、农行订单金额）'}" align="center" />
			<vxe-table-column slot="table-item" field="occupy" title="占位营收" align="center" />
			<vxe-table-column slot="table-item" field="outcome" title="扣款金额" :title-help="{message: '扣款金额（包含：缴纳电费、通道费、手续费、第三方抽成、开票税费）'}" align="center" />
			<vxe-table-column slot="table-item" field="get_occupy" title="缴费单" align="center" width="120px">
				<template v-slot="{ row }">
					<el-image style="width: 100px; height: 50px" fit="contain" :src="row.url" :preview-src-list="[row.url]"></el-image>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="refund" title="调整金额" align="center" />
			<vxe-table-column slot="table-item" field="profit" title="可分成金额" align="center">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="perall" title="共建方分成" align="center" />
			<vxe-table-column slot="table-item" field="remark" title="自营分成" align="center">
				<template v-slot="{ row }">
					{{ (row.profit - row.perall).toFixed(2) }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="ctime" title="创建时间" align="center" />
			<vxe-table-column slot="table-item" field="status" title="结算状态" align="center">
				<template v-slot="{row}">
					<template v-if="(row.status==0 && !row.ext.sync) || row.status == 3">
						{{ statusText[row.status] }}
					</template>
					<template v-else v-for="(item,index) in row.percent">
						<div v-if="index>0" style="height: 1px;background-color: #DCDFE6;margin: 3px 0px;"></div>
						{{ item.N }}<br/>
						{{ statusText[row.ext['status'+item.U]]||'未申请' }}						
					</template>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="applytime" title="申请结算时间" align="center" width="150px">
				<template v-slot="{row}">
					<template v-if="(row.status==0 && !row.ext.sync) || row.status == 3">
						
					</template>
					<template v-else v-for="(item,index) in row.percent">
						<div v-if="index>0" style="height: 1px;background-color: #DCDFE6;margin: 3px 0px;"></div>
						{{ row.ext["apply"+item.U]||'--' }}
					</template>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="250px">
				<template v-slot="{ row }">
					<template v-if="(row.status==0 && !row.ext.sync) || row.status == 3">
						<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('report.group_share/settleedit') && row.status==0">编辑</el-button>
						<el-button size="small" plain @click="syncOne(row)" v-if="$hasAccess('report.group_share/settleend') && row.status==0 && !row.ext.sync">同步</el-button>
					</template>
					<template v-else v-for="(item,index) in row.percent">
						<div v-if="index>0" style="height: 1px;background-color: #DCDFE6;margin: 3px 0px;"></div>
						<el-button size="small" plain @click="endOne(row, index)" v-if="$hasAccess('report.group_share/settleend') && row.status==1" :disabled="!row.ext['apply'+row.percent[index].U] || row.ext['end'+row.percent[index].U]">已打款</el-button>
						<el-button size="small" plain @click="cancelOne(row, index)" v-if="$hasAccess('report.group_share/settlecancel') && row.status < 2">作废</el-button>
					</template>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增/编辑 -->
		<el-dialog title="新增/编辑结算单" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="140px" class="form">
				<el-form-item label="结算周期" prop="dateRang">
					<el-date-picker size="small" v-model="editDialogFormData.dateRang" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结算日期">
					</el-date-picker>
				</el-form-item>
				<div style="font-weight: bold;font-size: 20px;padding-left: 20px;">收入</div>
				<el-form-item label="第三方占位营收" prop="other_occupy">
					<el-input size="small" type="number" placeholder="充电可提现" v-model="editDialogFormData.other_occupy">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
				<div style="font-weight: bold;font-size: 20px;padding-left: 20px;">扣款</div>				
				<el-form-item label="缴纳电费" prop="pay_ele">
					<el-input size="small" type="number" placeholder="应付电费" v-model="editDialogFormData.pay_ele">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
				<el-form-item label="上传附件" prop="pay_img">
					<upload type="image" :imgWidth="80" :imgHeight="80" :fileList="editDialogFormData.fileInfo" @changeFile="changeFile($event)"></upload>
				</el-form-item>
				<!-- <el-form-item label="第三方抽成" prop="pay_other">
					<el-input size="small" type="number" placeholder="第三方抽成" v-model="editDialogFormData.pay_other">
						<template slot="append">元</template>
					</el-input>
				</el-form-item> -->
				<div style="font-weight: bold;font-size: 20px;padding-left: 20px;">调整</div>
				<el-form-item label="调整费用" prop="refund">
					<el-input size="small" type="number" placeholder="调整费用" v-model="editDialogFormData.refund">
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
				<el-form-item label="调整说明" prop="remark">
					<el-input placeholder="调整说明" v-model="editDialogFormData.remark">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'report-group-settlelist',
		components: {
			Tables,upload
		},
		data() {
			return {
				// 表格信息
				tableName: '结算管理',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				statusText:{
					"0":"未结算",
					"1":"已申请",
					"2":"已结算",
					"3":"作废"
				},
				rules: {
					dateRang: [{
						required: true,
						message: '请输入结算周期',
						trigger: 'change'
					}],
					other_occupy: [{
						required: true,
						message: '请输入第三方占位营收',
						trigger: 'change'
					}],
					get_ele: [{
						required: true,
						message: '请输入充电可提现',
						trigger: 'change'
					}],
					get_occupy: [{
						required: true,
						message: '请输入占用可提现',
						trigger: 'change'
					}],
					pay_ele: [{
						required: true,
						message: '请输入应付电费',
						trigger: 'change'
					}],
					// pay_other: [{
					// 	required: true,
					// 	message: '请输入第三方抽成',
					// 	trigger: 'change'
					// }],
					pay_img: [{
						required: true,
						message: '请上传附件',
						trigger: 'change'
					}],
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {percent:[]},
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					group_id: this.$route.query.group_id,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Report.GroupShare.settlelist(params);
				this.List = res.data;
				for(let v of this.List){
					v.income = Number(v.order) + Number(v.other) + Number(v.nh);
					v.pipe = ((Number(v.income) + Number(v.occupy) + Number(v.other_occupy)) * 0.006).toFixed(2);
					v.outcome = Number(v.pay_ele) + Number(v.pay_other) + 10 + Number(v.pipe)/* + Number(v.pay_tax||0)*/;
					v.profit = (v.income + Number(v.occupy) + Number(v.other_occupy) + Number(v.refund||0) - v.outcome).toFixed(2);
					v.perall = 0;
					let pstr = "";
					for(let per of v.percent){
						pstr += per.N + ":	" + (per.P * v.profit / 100).toFixed(2) + "\n";
						v.perall += per.P * v.profit / 100;
					}
					v.perall = v.perall.toFixed(2);
					v.childData = [{
						income:"自营订单金额:	" + v.order + "\n第三方订单金额:	" + v.other + "\n农行订单金额:	" + v.nh,
						occupy:"自营占位营收:	" + v.occupy + "\n第三方占位营收:	" + v.other_occupy,
						pay:"缴纳电费:	" + v.pay_ele + "\n通道费:	" + v.pipe + "\n手续费:	10.00\n第三方抽成:	" + v.pay_other/* + "\n开票税费:	" + (v.pay_tax||0)*/,
						percent: pstr,
						reset:"	" + v.refund + "\n调整理由:	" + v.remark,
					},{
						income:"合计:	" + v.income,
						occupy:"占用合计:	" + (Number(v.occupy) + Number(v.other_occupy)).toFixed(2),
						pay:"扣款合计:	" + v.outcome.toFixed(2),
						percent:"共建分成合计:	" + v.perall,
						reset:"调整合计:	" + v.refund,
					}];
					v.outcome = v.outcome.toFixed(2);
					//this.List.push(v);
					//console.log(v);
				}
				//console.log(this.mergeCells);
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {};
			},
			// 新增
			addOne() {
				this.resetFormData();
				this.editDialogShow = true;
			},
			editOne(row) { // 编辑
				this.resetFormData();
				this.editDialogFormData = Object.assign({}, row);
				//this.editDialogFormData.dateRang = [this.editDialogFormData.btime, this.editDialogFormData.etime];
				this.$set(this.editDialogFormData, "dateRang", [this.editDialogFormData.btime, this.editDialogFormData.etime]);
				this.editDialogFormData.fileInfo = [{url:this.editDialogFormData.url}];
				delete this.editDialogFormData._XID;
				this.editDialogShow = true;
			},			
			syncOne(row) { // 同步 标识一下
				const params = {
					token: this.$store.state.user.token,
					id: row.id,
				}
				this.$api.Report.GroupShare.settlesync(params).then(()=>{
					this.$notify({
						title: '成功',
						message: '操作成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()					
				});
			},
			endOne(row, index) { // 结算
				this.$confirm('确定要结算该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id,
						uid: row.percent[index].U
					}
					await this.$api.Report.GroupShare.settleend(params)
					this.$notify({
						title: '成功',
						message: '操作成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			cancelOne(row) {// 作废
				this.$confirm('确定要作废该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Report.GroupShare.settlecancel(params)
					this.$notify({
						title: '成功',
						message: '操作成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Report.GroupShare.settledel(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false;
				this.$refs.xTable.refreshTable();
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					//console.log('valid', valid)
					if (valid) {
						this.editDialogFormData.group_id = this.$route.query.group_id;
						this.editDialogFormData.btime = this.editDialogFormData.dateRang[0];
						this.editDialogFormData.etime = this.editDialogFormData.dateRang[1];
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						if(this.editDialogFormData.ctime){
							await this.$api.Report.GroupShare.settleedit(params);
						}else{
							await this.$api.Report.GroupShare.settleadd(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog();
					}
				})
			},
			changeFile(fileInfo){ //上传的文件
				console.log("changeFile", fileInfo);
				this.editDialogFormData.fileInfo = fileInfo;
				this.editDialogFormData.pay_img = this.editDialogFormData.fileInfo[0].attach_id;
				//console.log(this.editDialogFormData);
			},
			viewOne(row){
				this.$router.push({
					name: "report-group-settleview",
					query: { group_id: row.group_id, id: row.id, btime: row.btime, etime: row.etime, min: row.ext.min, max: row.ext.max, min_other: row.ext.min_other, max_other: row.ext.max_other, min_nh: row.ext.min_nh, max_nh: row.ext.max_nh },
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
